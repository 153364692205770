.root {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral20-color);
  border-radius: var(--border-radius);
  position: relative;
  max-height: 290px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

}

.loader {
  top: 50%;
}

.item {
  display: flex;
  gap: 8px;
  padding: 16px;
  min-height: 72px;
  cursor: pointer;

  .root:not(.variantCheckbox) &:hover, .root:not(.variantCheckbox) &.selected {
    background-color: #E5EEFE;
  }

  &:first-child {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }

  &:last-child {
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  .root:not(.hasButton) &:nth-of-type(2n),
  .root.hasButton &:nth-of-type(2n + 1) {
    background: var(--neutral10-color);

    .root:not(.variantCheckbox) &:hover, .root:not(.variantCheckbox) &.selected {
      background-color: #E5EEFE;
    }
  }
  
}

.itemButton {
  display: flex;
  gap: 8px;
  padding: 16px;
  min-height: 72px;
  cursor: pointer;
  border-bottom: 1px solid var(--neutral40-color);;
  align-items: center;
  color: var(--secondary-color);

  &:hover {
    cursor: pointer;
    background-color: transparent;
  }
}

.imageContainer {
  width: 40px;
  flex: 0 0 40px;
}

.title {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black-color);
}

.description {
  font-size: 12px;
  line-height: 1.3;
  color: var(--neutral80-color);
}

.noDataMessage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  .hasButton & {
    top: calc(50% + 36px);
  }
}

.contentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.listContainer {
  position: relative;
}