.custom-checkbox {
  .form-check-input {
    position: relative;
    background: #e9e9e9;
    width: 24px;
    height: 24px;
    border-radius: 8px !important;
    border: unset;
    &.select &:focus {
      box-shadow: unset;
    }
    &:checked[type='checkbox']::before {
      content: '';
      width: 12px;
      height: 8px;
      position: absolute;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      transform: rotate(-38deg) translate(-50%, -50%);
      top: 21%;
      left: 56%;
      background: transparent;
      background-image: unset;
    }
    &:checked {
      background-color: #0059f1 !important;
      border-color: #0059f1;
    }
    &:checked[type='checkbox'] {
      background-image: unset;
      border-radius: 8px;
    }
  }
  p {
    margin: 0;
    padding: 5px 10px;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(255, 255, 255) !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.password-field {
  position: relative;
}

.eye-icon {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  // bottom: 2rem;
  // margin-top: -4rem;
  // left: 90%;
  cursor: pointer;
}

input.form-check-input {
  background-color: var(--neutral20-color);
  border: none;
  &.selectAll {
    background-color: white !important;
  }
}

.google-login {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem !important;
    height: 3.5rem;
    width: 5rem;
    border-radius: 0.5rem;
  }
}

input.input-error {
  background-color: var(--neutral20-color);
}

form input,
form input:hover,
form input:focus {
  box-shadow: none !important;
}
.checkbox-selection {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;

  label {
    position: absolute;
    cursor: pointer;
    width: 22px;
    height: 22px;
    left: 0;
    top: 0;
    border: 1px solid #E9E9E9;
    border-radius: var(--card-actions-border-radius);
    background-color: var(--white-color);
  }
  label:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 5px;
    top: 6px;
    left: 5px;
    opacity: 0;
    transform: rotate(-45deg);
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
  }
  input[type='checkbox'] {
    display: block;
    width: 22px;
    height: 22px;
    visibility: hidden;
  }
  .selectCard:checked + label {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  .selectCard:checked + label:after {
    opacity: 1;
  }
}

input.phoneInput {
  min-height: 48px;
  &ReadOnly {
    background-color: white !important;
    border: none !important;
    display: inline;
  }
}

body
  > div.adminUserSidebar.offcanvas.offcanvas-end.show
  > div.offcanvas-body
  > div
  > form
  > div.px-2.row
  > div:nth-child(2)
  > div
  > input,
input.phoneInputReadOnly.react-tel-input.form-control {
  background-color: white !important;
  background: white !important;
  border: none !important;
  display: inline;
}

header.header.profileSidebar {
  max-height: 72px !important;
  & > img {
    height: 60px;
    width: 60px;
  }
  & > .simpleImage {
    height: 60px !important;
    width: 60px !important;
  }
}

.profileSidebarImg {
  border-radius: var(--border-radius);
  & > * {
    border-radius: var(--border-radius);
    height: 60px;
    width: 60px;
  }
}

.adminUserSidebar {
  width: 27.25rem;
}

.fontLightWeight {
  font-weight: 200;
}

.google-login-icon button {
  padding: 0.2rem;
}
.Toastify__toast-icon {
  width: 2em !important;
}
.toastIcon {
  &.succcess {
    color: var(--success-color);
  }
}
.toastIconColumn.col-1 {
  width: 36px !important;
}
.Toastify__toast-container {
  width: auto !important;
  max-width: 735px;
  padding: 0;
}
.toast-container.largeToastify {
  // width: 65% !important;
  // width: 100% !important;
  max-width: 735px;
  margin: 0 auto;
}

.input-box {
  background-color: var(--neutral20-color);
  color: var(--input-text-color);
  border: none !important;
  border-radius: 8px;
}

.input-error {
  background-color: #fceae8 !important;
  border: 1px solid var(--error-color) !important;
  color: var(--error-color) !important;
  font-weight: 400 !important;
}

.back-button {
  padding: 8px 14px 8px 8px;
  background-color: var(--neutral20-color);
  text-decoration: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.input-box-disabled {
  background-color: var(--white-color) !important;
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.toc-text {
  font-size: 1rem;
  margin-left: 10px;
}

.phoneInput {
  width: 100% !important;
  background-color: var(--neutral20-color) !important;
  color: var(--input-text-color);
  border: none !important;
  border-radius: 8px !important;
}

.flag-dropdown {
  background-color: var(--neutral20-color) !important;
}

.product-select {
  margin-top: 5rem;
  padding-left: 10rem;
  padding-right: 10rem;
}

.select-form {
  width: 100%;
}

label.form-has-errors {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #e4291d;
}
