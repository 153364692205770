.closeButton {
  height: 24px;
  width: 24px;
  background: var(--neutral80-color);
  border-radius: 8px;
  position: absolute;
  border: none;
  outline: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.btn1 {
    top: 20px;
    right: 1rem;
  }
  &.btn2 {
    top: 28px;
    right: 1.5rem;
  }

  svg {
    // width: 9px;
  }
}

.title {
  height: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 132%;
}

.previewRenderForm {
  // flex: 0 0 311px;
  width: 100%;
}

.modalDialog {
  margin-top: calc(var(--curator-header-height) + 1em);
  margin-left: auto;
  margin-right: 0.75em;
  color: #fff;
  max-width: 655px;

  &.small {
    max-width: 363px;

    :global(.modal-body) {
      padding: 1.5rem;
    }
  }

  :global(.form-label) {
    height: 1.5rem;
    font-size: 0.875rem;
    line-height: 170%;
  }
}

.backdrop {
  opacity: 0 !important;
}

.modalContent {
  background: var(--black-color) !important;
  border: none;
  border-radius: 8px;
  padding: 0;
}

.container {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  position: relative;

  &.containerColumn {
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem;
  }

  :global(.form-check-input) {
    margin-top: 0;
  }
}
.resolutionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  :global(.lock-icon) {
    width: 3rem;
    padding: 0;
  }
}
.resolutionInput {

  width: 124px;

  :global(.form-control) {
    padding: 0.75rem;
    height: 3rem;
    background-color: var(--neutral80-color);
    color: var(--white-color);
    border: none;
    border-radius: 8px;
  }

}


.renderCameraListContainer {
  min-height: 280px;
  max-height: 320px;
  flex: 0 0 296px;

  .renderCameraList {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 296px;
  }
}

.previewCameraListContainer {
  // max-height: 264px;

  .previewCameraList {
    max-height: 264px;
  }
}

.renderPopupBottom {
  margin-top: 0;
  height: calc(100% - var(--curator-header-height) - 1em);

  .modalContent {
    position: absolute;
    bottom: 0;
  }
}