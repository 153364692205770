.container {
  background: var(--light-blue);
  border-radius: 8px;
  overflow: hidden;
  color:  var(--secondary-color);

  .button {
    background: var(--light-blue);

    &:hover {
      background: var(--secondary-color);
    }
  }

  input {
    background-color: var(--light-blue) !important; 
    color: var(--secondary-color) !important;
    font-size: 14px;
  }

  button {
    height: 32px;
    padding: 0 16px !important;
    border: none;
    outline: none;
    border-radius: 8px !important;
    background-color: var(--white-color) !important; 
    color: var(--secondary-color) !important;
    margin-right: 1rem;
    align-self: center;
    font-size: 14px !important;

    &:hover {
      background-color: var(--neutral10-color) !important;
    }
  }
}

.inputGroupText {
  border: none;
  background-color: var(--light-blue);
  cursor: pointer;
  color: var(--secondary-color);
  position: relative;

  svg {
    transform: translateX(10px);
  }

  &:after {
    content: '';
    height: 24px;
    width: 1px;
    background-color: #0059f1;
    position: absolute;
    right: -4px;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);
  }
}