.root {
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  border-bottom: 1px solid var(--neutral40-color);

  // &:last-of-type {
  //   border-bottom: none;
  // }
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: var(--neutral80-color);

}

.editButton {
  align-self: flex-start;
  height: 24px;
  width: 24px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-left: 0.5rem;
  color: var(--secondary-color);
}