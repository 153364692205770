.root {
  z-index: 1055;
}

.closeButton {
  position: absolute;
  top: 22px;
  right: 20px;
  background: var(--neutral20-color);
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 8px;
}

.thumbnail {
  width: 56px;
  flex: 0 0 56px;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 3rem 1rem 1rem;
  background: var(--neutral10-color);
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
  gap: 8px;


  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.32;
    word-break: break-word;
  }

  .headerInfo {
    font-size: 14px;
    line-height: 1.7;
    color: var(--neutral80-color);
  }
}
