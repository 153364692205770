.root {
    padding: 0px 16px 0px 24px;
    border-bottom: 1px solid var(--neutral20-color);
    
    > * {
      padding: 12px 0;
    }
    
    &.dark {
      border-bottom: 1px solid var(--neutral80-color);
    }

    &:not(.fav):last-of-type {
      border-bottom: none;
    }

    &.fav {
      height: 64px;
      border-bottom: 1px solid var(--neutral20-color);
    }
    
    :global(.form-check) {
      // margin-top: 8px;
      // margin-bottom: 8px;
      margin-bottom: 0;
      padding-left: 0;
      font-weight: normal;
      // font-size: 12px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;

      // &:last-of-type {
      //   margin-bottom: 8px;
      // }
      :global(.count) {
        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral60-color);
        }
      }

    }

    :global(.styled-checkbox) {
      background-color: var(--neutral20-color);
      border: 1px solid var(--neutral20-color);
    }
    // :global(.form-check-label) {
    //   margin-left: 0.5em;
    // }

    :global(.form-check-input) {
      flex: 0 0 24px;
      margin-top: 0;
      margin-bottom: 0;
    }

    

    .more {
      // margin-bottom: 12px;
      // margin-top: 24px;

      span {
        cursor: pointer;
        color: var(--secondary-color);
        text-decoration: none;
        // margin-bottom: 12px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        user-select: none;
      }
    }

    h5 {
      // margin-top: 12px;
      // margin-bottom: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.5;
    }
  }