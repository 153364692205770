
// TODO: refactor this !!!


.team-tab-users {
  span {
    border: 1px solid #e9e9e9;
    padding: 8px 1rem !important;
  }
}
.card-grid {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  &.custom-card-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(269px, 1fr));
    .thumbnail-card {
      width: auto;
    }
  }
}

.thumbnail-card {
  // min-width: calc(var(--thumbnail-width)/1.5);
  // width: 100%;
  width: var(--thumbnail-width);
  // height: var(--thumbnail-width);
  // display: flex;
  // flex-direction: column;
  border-radius: var(--border-radius) !important;
  background-color: var(--neutral20-color);
  border: none;
  overflow: hidden;

  * {
    margin: 0;
    padding: 0;
    font-family: var(--font-family) !important;
  }
}

.thumbnail-card-top-section {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.thumbnail-img {
  position: relative;
  overflow: hidden;

  // border-top-left-radius: var(--border-radius) !important;
  // border-top-right-radius: var(--border-radius) !important;
  border-radius: var(--border-radius) !important;

  figure {
    width: 100%;
    padding-top: 79.4%;
    border-radius: var(--border-radius);
    overflow: hidden;
    cursor: pointer;
    position: relative;

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      // width: var(--thumbnail-width);
      // height: calc(var(--thumbnail-width) - var(--thumbnail-info-height));
      object-position: center;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: var(--border-radius) !important;
      border-top-right-radius: var(--border-radius) !important;
    }
  }
}
.thumbnail-img {
  &:hover {
    img {  
      transform: scale(1.05);
    }
  }
}
.thumbnail-action {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%; // Ashish Agrawal - June 03, 2022

  .thumbnail-action-favourite,
  .thumbnail-action-checkbox {
    opacity: 0;
  }

  .thumbnail-card:hover & {
    .thumbnail-action-favourite,
    .thumbnail-action-checkbox {
      opacity: 1;
    }
    .thumbnail-action-checkbox {
      label {
        border: 1px solid #e9e9e9;
      }
      .selectCard {
        &:checked + label {
          // border: 1px solid #e9e9e9;
          border: 1px solid transparent;
        }
      }
    }
  }

  &--selected {
    .thumbnail-action-checkbox {
      opacity: 1;
    }
  }

  &--favourite {
    .thumbnail-action-favourite {
      opacity: 1;
    }
  }
}

.action-list {
  position: relative;
  width: 100%;
  padding: 1em;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  // z-index: 100;

  li {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;

    label {
      position: absolute;
      cursor: pointer;
      width: 22px;
      height: 22px;
      left: 0;
      top: 0;
      border: none;
      border-radius: var(--card-actions-border-radius);
      background-color: var(--white-color);
    }

    label:after {
      position: absolute;
      content: '';
      width: 10px;
      height: 5px;
      top: 6px;
      left: 5px;
      opacity: 0;
      transform: rotate(-45deg);
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
    }

    .favouriteCard + label {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: auto;
      right: 0;
      background-color: var(--black-color);
    }

    input[type='checkbox'] {
      display: block;
      width: 15px;
      height: 15px;
      visibility: hidden;
    }

    .selectCard:checked + label {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
    }

    .selectCard:checked + label:after {
      opacity: 1;
    }

    .favouriteCard + label:after {
      display: none;
    }

    .favouriteCard:checked + label {
      // background-color: var(--red);
      // border-color: var(--red);
    }

    .favouriteCard:checked + label:after {
      opacity: 1;
    }
  }
}

.thumbnail-tag {
  position: absolute;
  width: calc(100% - 2em);
  padding: 1em 1em 0.75em;
  left: 0;
  bottom: 0;

  .tag {
    margin: 0 0.5em 0.5em 0;
    padding: 0.25em 0.65em;
    font-size: 0.62em;
    color: var(--black-color);
    display: inline-block;
    border-radius: 4px;
    background-color: var(--white-color);
  }
}

.thumbnail-info {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  // align-items: flex-start;
  width: 100%;
  // height: var(--thumbnail-info-height);
  padding: 6px 1rem;

  .inner-info {
    flex: 0 0 calc(100% - 20px);
    width: calc(100% - 20px);
  }

  .inner-action {
    flex: 0 0 20px;
    width: 20px;
  }

  .thumbnail-title {
    margin-bottom: 0.25em;
    color: var(--black-color);
    // font-size: calc(var(--base-font-size) / 1.25);
    font-size: 1rem;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5;
    display: block;
  }

  a.thumbnail-title {
    cursor: pointer;
    &:hover {
      color: var(--secondary-color);
    }
  }

  .thumbnail-etime {
    color: var(--neutral80-color);
    font-size: 0.75rem;
    font-weight: 400;
  }

  .inner-action {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    // align-items: flex-start;
    width: 100%;
    // height: var(--thumbnail-info-height);
    padding: 10px 0.5rem;

    .card-action {
      cursor: pointer;
      // padding: 5px 10px;
      padding: 0;
    }

    a,
    div {
      position: relative;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 10px;
      height: 16px;
      color: var(--black-color);
      font-size: 0.875rem;
      cursor: pointer;
    }

    ul {
      position: absolute;
      top: -48px;
      left: 0;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      border-radius: var(--card-actions-border-radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid var(--white-color);
      background-color: var(--neutral20-color);

      li {
        height: auto;

        &:hover {
          img {
            transform: scale(1.25);
          }
        }
        img {
          // width: 100%;
          max-width: 15px;
          transform: scale(1);
          transition: all 100ms ease;
        }
        > div {
          color: var(--neutral80-color);
          width: 16px;
        }
      }
      li.no-text {
      }
      li.has-text {
        width: auto;

        > div {
          width: auto !important;

          > span {
            font-size: 0.85rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.render-status {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
  }
}

.render-status.renderDone {
  &:after {
    background-color: var(--render-done);
  }
}

.render-status.renderFailed {
  &:after {
    background-color: var(--render-failed);
  }
}

.render-status.renderUnderReview {
  &:after {
    background-color: var(--render-failed);
  }
}

.render-status.renderSelected, 
.render-status.camSelected {
  &:after {
    background-color: var(--render-selected);
  }
}

.render-status.render-unselected {
  &:after {
    background-color: var(--render-unselected);
  }
}

// responsiveness

// @media only screen and (max-width: 576px){
//     .card-grid > div{
//         width: calc(100% - 15px);
//         flex: 0 0 calc(100% - 15px);
//     }
// }
// @media only screen and (min-width: 577px) and (max-width: 767px){
//     .card-grid > div{
//         width: calc(50% - 15px);
//         flex: 0 0 calc(50% - 15px);
//     }
// }
// @media only screen and (min-width: 768px) and (max-width: 1024px){
//     .card-grid > div{
//         width: calc(33.33% - 15px);
//         flex: 0 0 calc(33.33% - 15px);
//     }
// }
// @media only screen and (min-width: 1025px){
//     .card-grid > div{
//         width: calc(25% - 15px);
//         flex: 0 0 calc(25% - 15px);
//     }
// }

.custom-imges {
  .avatar {
    display: inline-flex;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    img {
      width: 20px !important;
      height: 20px;
      display: block;
      margin: 0;
      border-radius: 4px;
    }
  }

  .avatar:not(:first-child) {
    margin-left: -10px;
  }

  .avtar-count-part {
    background: blue;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
    line-height: normal;
    color: #fff;
  }
  &.user-icon-top {
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      img {
        width: 40px !important;
        height: 40px;
        border-radius: 8px;
      }
    }
  }
}
.main-item-toggel {
  position: absolute;
  bottom: -4px;
  width: 100%;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #e4e4e4;

  .inner-item-part {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    &:nth-child(even) {
      background: #f2f2f2;
    }
    .item-image-text {
      display: flex;
      align-items: center;
      span {
        color: #2d3136;
        font-weight: 500;
      }
      img {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    .item-hours-part {
      color: #2d3136;
    }
  }
}

.custom-thumbnail-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
  grid-gap: 1.5rem;
  .thumbnail-card {
    width: inherit;
  }
}
