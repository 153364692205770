.tabs {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 3px;
    border-radius: 4px;
  }

  :global(.page-link) {
    white-space: nowrap;
  }
}