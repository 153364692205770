.filterSubmenu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  background-color: #3d4145;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  :global {
    .filter-checkbox {
      padding: 0px 31px 0px 21px;
      border-bottom: 1px solid #5a5c61;
      .form-check {
        margin-top: 16px;
        margin-bottom: 24px !important;
        font-weight: normal;
        font-size: 16px;
        display: flex;
        align-items: flex-start;
      }

      .form-check:last-of-type {
        margin-bottom: 20px !important;
      }


      .form-check-input {
        margin-top: 0;
      }

      .more {
        margin-bottom: 12px;
        a, span {
          color: #669bf7;
          text-decoration: none;
          margin-bottom: 12px;
          font-weight: normal;
          font-size: 16px;
          cursor: pointer;
          user-select: none;
        }
      }
      h5 {
        margin-top: 12px;
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    
    .form-check-label {
      // word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      align-self: flex-end;
      cursor: pointer;
    }
  }
}