.error {
  color: var(--error) !important;
}

m-bottom-1 {
  margin-bottom: 1rem;
}

.m-bottom-2 {
  margin-bottom: 2rem;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.page-wrapper {
  padding-right: 0;
  overflow-x: hidden;

  & .row:first-child {
    margin-right: 0 !important;
  }
}

.mb0 {
  margin-bottom: 0px !important;
}

.btnoutline p {
  margin-left: 10px;
}

.mrg-rt {
  margin-right: 1rem;
}

.mrg-rt6 {
  margin-right: 6rem;
}

.btnwidth {
  width: 132px !important;
}

.height {
  height: 40px;
}

.allpadding {
  padding-top: 18px;
  padding-right: 24px;
}

.d-flex .allpadding img {
  border-radius: 12%;
}

.leftbackground {
  width: 100%;
  background: transparent;
  border-radius: var(--border-radius);
  padding: 2%;
}

.leftbackgrounds {
  width: 100%;
  background: white;
  border-radius: var(--border-radius);
  padding: 2%;
}

.p-0 {
  padding: 0% !important;
}

.p-0 .projects-texture-state .container-fluid .texturepreview-right .texturezoom-preview img {
  margin: 0px;
}

.linktrg {
  color: #0159f1 !important;
}

.amountrate {
  color: black;
  font-size: 24px;
}

.mrg-rt4 {
  margin-right: 4rem;
}

.mt-4 {
  margin-top: 4 !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-18 {
  margin-top: 18px;
}

.fntweightmore {
  font-weight: 400 !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.orngbtn {
  background-color: #ffa200 !important;
  width: 100%;
}

.block {
  display: block !important;
}

.borderradius {
  border: 1px solid #ced4da;
  padding: 6px;
  border-radius: 4px;
}

.trnsparent {
  color: transparent;
}

.fullWidth {
  width: 100% !important;
}

.minWidth200 {
  min-width: 200px;
}
.minWidth165 {
  min-width: 165px;
}
.minWidth135 {
  min-width: 135px;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.fontSize85 {
  font-size: 0.85em;
}

.hide-btn {
  display: none !important;
}

.error-red {
  color: red;
}

.header-name-of-my-project {
  h1 {
    font-size: 2rem;
  }
}

.topProductButtons {
  max-height: 40px;
}

.cursorPointer {
  cursor: pointer;
}

.font16 {
  font-size: 16px;
}
.font14 {
  font-size: 14px;
}
.font12 {
  font-size: 12px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamsPageCard {
  padding: 0.5rem 1rem !important;
  background-color: #ffffff;

  .teamsPageCard__title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .teamsPageCard__role {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: #6c757d;
  }

  &.inactive {
    .thumbnail-title {
      padding-left: 0;
    }
  }
  .TeamMainCardStatus {
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: var(--border-radius);
    left: 0;
    top: 50%;
    transform: translateY(calc(-50%));
  }
}
.main-container {
  padding-bottom: var(--bs-gutter-x, 0.75rem);
}

.lfSelect__menu,
.lfSelect__control,
.lfSelect__menu-list {
  width: 158px !important;
}

.lfSelect__control {
  padding: 6px 10px;
  height: 40px !important;
  border-radius: var(--border-radius) !important;

  .lfSelect__value-container {
    width: 110px;
    flex: 0 0 110px;
    padding: 0;
  }

  .lfSelect__indicators {
    width: 24px;
    flex: 0 0 24px;
    height: 24px;

    .lfSelect__indicator {
      padding: 4px;
    }
  }

  .lfSelect__input-container {
    margin: 0;
    padding: 0;
  }

  .lfSelect__input-container,
  .lfSelect__placeholder,
  .lfSelect__input,
  .lfSelect__single-value {
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: var(--neutral80-color) !important;
    line-height: 170% !important;
  }
}
.lfSelect__menu {
  background-color: var(--white-color);
  box-shadow: none !important;
  border-radius: var(--border-radius) !important;
}
.lfSelect__menu-list {
  display: flex;
  flex-direction: column;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--black-color) !important;
}

.lfSelect__option {
  color: var(--black-color) !important;
  cursor: pointer !important;
  // height: 56px !important;
  padding: 16px !important;
  font-size: 16px !important;
  line-height: 24px;
  justify-content: center;
  background-color: transparent !important;

  &.lfSelect__option--is-selected {
  //   // background-color: var(--white-color) !important;
  //   // color: inherit !important;
  //   // // font-weight: 700;
    pointer-events: none;
    // cursor: default !important;
    border-top: none !important;
    background-color: var(--neutral10-color) !important;
    color: var(--neutral60-color) !important;
    border-bottom: 1px solid var(--neutral40-color) !important;
  }
  &:not(.lfSelect__option--is-selected) {
    order: 1;

    &:hover {
      background-color: #deebff !important;
    }
  }
  // &.lfSelect__option:first-of-type {
  //   border-top: none !important;
  //   background-color: var(--neutral10-color) !important;
  //   color: var(--neutral60-color) !important;
  //   border-bottom: 1px solid var(--neutral40-color) !important;
  // }
  // &.lfSelect__option--is-focused:not(:first-of-type) {
  //   background-color: #deebff !important;
  // }

  // &.lfSelect__option--is-selected:first-of-type {
  //   font-weight: normal;
  // }
  // &.lfSelect__option--is-selected:last-of-type {
  //   border-bottom: none !important;
  // }
}

.lfSelectMulti__control {
  padding-left: 0px !important;
  padding-right: 16px !important;
  background-color: white !important;
  min-height: fit-content !important;
  height: fit-content !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  overflow-y: auto !important;
}

.lfSelectMulti__value-container {
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.lfSelectMulti__multi-value {
  // display: contents;
}

//  This css for button is placed here as it is being used in different parts of application
.bigButton {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  color: var(--black-color);
  gap: 4px;
  min-width: 85px;
  width: auto;
  height: 48px;
  border-radius: var(--border-radius);
  border: none;

  &:not(.keep-text) {
    > img + span, > svg + span {
      @media screen and (max-width: 1380px) {
        display: none;
      }
    }
    @media screen and (max-width: 1380px) {
      padding: 12px;
      min-width: 48px;
      // max-width: 48px;
    }
  }


  img,
  svg {
    transform: scale(1);
    transition: all 100ms ease;
  }

  &:hover {
    img,
    svg {
      transform: scale(1.25);
    }
  }

  &.btn-secondary,
  &.btn-outline-dark,
  &.btn-primary {
    color: var(--black-color);
    &:hover {
      color: var(--black-color);
    }
  }

  > span {
    color: var(--black-color);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }

  .circularProgress {
    border-color: var(--black-color);
    border-left-color: transparent;
  }

}

.top-buttons {
  button.bigButton:disabled {
    display: none !important;
  }
}

// button[title='Delete'],
// button[title='Archive'],
// button[title='Empty trash'],
// button[title='Move to textures'],
// button[title='Delete request'],
button.lf-btn-3 {
  border: 1px solid var(--neutral80-color) !important;
  background-color: var(--background-color) !important;

  > span {
    color: var(--neutral80-color) !important;
  }
}

// button[title='Add To Collection'],
// button[title='Share'],
// button[title='Edit'],
button.lf-btn-2 {
  background-color: var(--neutral20-color) !important;
}

// button[title='Cancel'],
// button[title='Download'],
// button[title='New Project'],
button[title='Upload'],
// button[title='Upload Textures'],
// button[title='Invite User'],
// button[title='Import'],
// button[title='Submit invitation'], 
button.lf-btn-1
{
  background-color: var(--primary-color) !important;
}


.lf-capitalize {
  text-transform: capitalize;
}