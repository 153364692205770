.fullWidth {
  width: 100% !important;
}

.smallSize.smallSize {
  
  .cardBody {
    height: auto;
  }

  :global(.thumbnail-title) {
    font-size: 12px;
    line-height: 18px;
    padding-top: 2px;
    margin-bottom: 0;
  }

  .secondLine {
    font-size: 10px;
  }

  :global(.thumbnail-etime) {
    font-size: 10px;
    line-height: 1.3;
  }

  :global(.card-body.thumbnail-info) {
    padding: 0 8px 5px;
    min-height: 38px;
  }
}

.favouriteLoading {
  pointer-events: none;
  opacity: 1 !important;
}

.secondLine {
  color: var(--neutral80-color);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actionsList {
  li {
    width: auto !important;

    &:hover {
      svg {
        transform: scale(1.25);
      }
    }

    & > div {
      min-width: 24px;
    }
  }

  &.withText .actionItemText {
    display: inline-block;
    margin-left: 4.32px;
  }

  svg {
    transition: all 100ms ease;
    transform: scale(1);
    flex: 0 0 24px;
  }

  .actionItemText {
    display: none;
    white-space: nowrap;
  }

  &.actionListSpaceAround {
    justify-content: space-around !important;

    > li > div {
      width: auto !important;
    }
  }
  &.actionListSpaceBetween {
    justify-content: space-between !important;
    
    > li > div {
      width: auto !important;
    }
  }
}

.cardBody {
  position: relative;
  height: 56px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
  }

  &.success {
    &:after {
      background-color: var(--render-done);
    }
  }

  &.error {
    &:after {
      background-color: var(--render-failed);
    }
  }

  &.selected {
    &::after {
      background-color: var(--render-selected);
    }
  }


  :global(.thumbnail-title).clickable:hover {
    color: var(--secondary-color);
  }
}

.stepData {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: var(--neutral80-color);
  margin-left: auto !important; 
}

.clickable {
  cursor: pointer;
}