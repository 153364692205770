// delete button color #5A5C61

.root {
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  white-space: nowrap;

  
  &.fullWidth {
    width: 100%;
  }
  

  &:global(.btn-lg) {
    font-size: 1rem;
  }

  &:global(.btn-secondary) {
    background-color: var(--neutral20-color);
    border-color: var(--neutral20-color);
    color: var(--black-color);
  }

  &:global(.btn-outlined-secondary) {
    border-color: var(--neutral20-color);
    color: var(--black-color);
  }

  &.loading {
    pointer-events: none;

    .iconBefore, .iconAfter, .text {
      visibility: hidden;
    }
  }

  &.secondaryHoverStyle:hover {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: var(--white-color);
  }

  &:global(.btn-light) {
    background-color: #E9E9E9;
    border-color: #E9E9E9;
  }

  &.active {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: var(--white-color);
  }

  &:global(.btn-warning) {
    color: var(--black-color);
  }

  &:global(.btn-warning):disabled {
    background: var(--light-yellow);
    border-color: var(--light-yellow);
    opacity: 1;
  }

  &:global(.btn-outline-warning):hover {
    // // TODO: it would be better to just replace bootstrap color variables, but it'll take time to do it
    color: var(--black-color);
  }
}

.iconBefore {
  margin-right: 4px;
  display: flex;
  margin-left: -2px;
}

.iconAfter {
  margin-left: 4px;
  display: flex;
  margin-right: -2px;
}

.xlg {
  height :48px;
  font-size: 1rem;
  padding-left: 22px;
  padding-right: 22px;

  .iconBefore {
    margin-left: -6px;
    margin-right: 8px;
  }
}

.xs {
  height :32px;
  font-size: 0.875rem;
}