.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: var(--neutral20-color);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: 4px;
  top: 4px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(25px);
  background-color: #ffffff;
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: #0059f1;
}

.form-check-input:disabled ~ span.switch,
.form-check-input[disabled] ~ span.switch {
  pointer-events: none;
  background-color: var(--neutral20-color) !important;
}
