.input {
  background: var(--neutral20-color);
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  height: 48px;
  padding: 11px;
  font-size: 16px;
  color: var(--black-color);
  
  &::placeholder {
    color: var(--neutral80-color);
  }

  .error & {
    border-color: var(--error-color);
    color: var(--error-color);
    background-color: var(--error10-color);

    &::placeholder {
      color: var(--error-color);
    }
  }
}

.label {
  font-size: 0.875rem;
  line-height: 1.7;
  margin-bottom: 8px;
  color: var(--neutral80-color);

  .error & {
    color: var(--error-color);
  }
}

