.root {
  padding-top: 1.5em;
  padding-bottom: 2em;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.cardList {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.card {
  flex: 1;
  border: 1px solid #e9e9e9;
  height: 104px;
  padding: 1rem;
  border-radius: var(--border-radius);
  background-color: var(--white-color);
  max-width: calc((100% - 2 * 2rem) / 3);
}

.tableDropdown {


  &:global(.show) {
    .arrow {
      background-color: var(--secondary-color);
      color: var(--white-color);
      // border-radius: 4px;
    }
  }

  :global(.dropdown-toggle) {
    background-color: transparent !important;
    color: var(--black-color) !important;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    height: 24px;

    &:after {
      display: none;
    }
  }

  :global(.dropdown-menu) {
    border: 1px solid var(--black-color);
    border-radius: var(--border-radius);
    margin-top: 8px;
    padding: 0;
    max-height: 350px;
    overflow-y: auto;
  }

  :global(.dropdown-item) {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;

    &:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:hover {
      background-color: #E5EEFE;
      color: var(--black-color);
    }

    &:global(.active) {
      background-color: var(--neutral10-color);
      color: var(--neutral60-color);
    }

    .closeIcon {
      color: var(--neutral80-color);
    }
  }
}

.arrow {
  width: 24px;
  height: 24px;
  border-radius: 4px;

  margin-left: 8px;
  display: flex;
  position: relative;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    border-radius: 4px;
    border: 1px solid var(--neutral40-color);
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}

.tableBody {
  position: relative;

  tr:first-of-type td:first-child { border-top-left-radius: 8px; }
  tr:first-of-type td:last-child { border-top-right-radius: 8px; }
  tr:last-child td:first-child { border-bottom-left-radius: 8px; }
  tr:last-child td:last-child { border-bottom-right-radius: 8px; }


  td {
    border: none;
    padding: 12px 16px;
  }

  tr:first-of-type {
    td {
      border-top: 1px solid #E9E9E9;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid #E9E9E9;
    }
  }

  tr {
    td:first-child {
      border-left: 1px solid #E9E9E9;
    }

    td:last-child {
      border-right: 1px solid #E9E9E9;
    }
  }

}

.tableColumn {
  &:last-child {
    text-align: right;
  }
}

.backdrop {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: var(--border-radius);
}

.noTransactionsText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 700;
}

.icon {
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.renderBody {
  background-color: var(--background-color);
  position: fixed;
}

.renderSidebar {
  background-color: #fff;
  z-index: 300;
}