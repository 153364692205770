.container {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;


  :global(.page-item:first-child .page-link) {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  :global(.page-item:last-child .page-link) {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  // li {
  //   list-style-type: none;
  //   height: 40px;
  //   color: var(--neutral80-color);

  //   &:last-child {
  //     border-top-right-radius: 0.5rem;
  //     border-bottom-right-radius: 0.5rem;
  //   }

  //   &:first-child {
  //     border-top-left-radius: 0.5rem;
  //     border-bottom-left-radius: 0.5rem;
  //   }

  //   .active {
  //     border-color: var(--neutral20-color);
  //   }
  // }
}

.prevArrow {
  transform: rotate(90deg);
}

.nextArrow {
  transform: rotate(-90deg);
}