.imageList {
  display: flex;
  gap: 12px;
  margin-bottom: 18px;
}

.imageContainer {
  width: 100%;
  max-width: 120px;
  max-height: 120px;
}

.imgPreviewContainer {
  width: 388px !important;
  height: 330px !important;
  border-radius: var(--border-radius);

  > img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 388px;
    height: 330px;
    object-fit: cover;
    object-position: center;
    border-radius: var(--border-radius);
  }

  :global(.magnifying-glass.circle) {
    width: 200px !important;
    height: 200px !important;
    box-shadow: none;
    border: 1px solid var(--neutral80-color) !important;
  }
}

.roomSectionTitle {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

.roomSectionLink {
  font-size: 12px;
  line-height: 1.5;
  color: var(--black-color);
  margin-bottom: 0.5rem;

  &:hover {
    color: var(--black-color);
  }
}

.imageBody {
  img {
    border-radius: 8px !important;
  }
}