
.notificationWrapper {
  position: fixed;
  width: var(--notifications-sidebar-width);
  height: calc(100vh - var(--header-height));
  padding: 24px 24px 24px 19px;
  background-color: var(--white-color);
  top: var(--header-height);
  right: 0;
  transition: all 300ms ease;
  overflow: hidden;
  z-index: 150;
  filter: drop-shadow(-8px 0px 16px rgba(45, 49, 54, 0.16));

  > div {
    margin: 0 auto;
    width: 393px;
  }

  :global(.progressContainer) {
    top: 50% !important;
    left: 50% !important;
  }

  .notificationHeader{
    position: sticky;
    z-index: 20;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > button {
        width: 32px;
        flex: 0 0 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          margin: 0;
        }
      }
    }

    &.filters  {
      margin: 24px 0 8px;

      :global(.filter_container) {
        margin-bottom: 16px;
      }
    }
  }
  .notificationHeader:first-child{
    padding-bottom: 24px;
    z-index: 30;

    &::after{
      content: "";
      position: absolute;
      width: calc(100% + 43px);
      left: -19px;
      bottom: 0;
      border-bottom: 1px solid var(--neutral20-color);
    }
  }

  .title{
    margin: 0;
    position: relative;
    width: 100%;
    padding: 0;
    color: var(--black-color);
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 132%;
  }

  button {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    outline: none;
  }

  .notificationsContainer{
    width: 100%;
    height: calc(100vh - var(--header-height) - 215px);
    padding-right: 10px;
    overflow-y: auto;
  }

  .notificationTitle{
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--black-color);

    span{
      margin-left: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: var(--neutral60-color);
    }
  }

  .notificationCard{
    position: relative;
    margin-bottom: 8px;
    padding: 16px;
    border-radius: var(--border-radius);
    border: 1px solid var(--neutral20-color);
    overflow: hidden;
    transition: all 100ms ease;

    &:hover {
      box-shadow: 0px 1px 2px rgba(0,0,0,0.15);

      svg {
        transform: scale(1.25);
      }
    }

    *{
      margin: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
    }

    // img{
    //   padding: 0;
    // }

    svg {
      margin-right: 4px;
      transform: scale(1);
      transform: all 100ms ease;
    }

    h5{
      font-size: 16px;
      line-height: 24px;
      color: var(--black-color);
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;

      > a {
        text-decoration: none;
        color: var(--secondary-color);
      }
    }
    p{
      font-size: 14px;
      line-height: 170%;
      color: var(--neutral80-color);
    }
  }

  .notificationCard:not(.unread, .muted) {
    background: var(--white-color);
  }

  .unread {
    cursor: pointer;
  }
  .unread, .muted {
    border: 1px solid var(--neutral10-color);
    background: var(--neutral10-color);
  }
  .unread{
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      width: 3px;
      height: 100%;
      background: var(--primary-color);
    }
  }

  .notificationAction {
    position: absolute;
    width: 210px !important;
    top: 43px;
    right: 0;
    background: var(--black-color);
    border-radius: var(--border-radius);
    z-index: 30;

    &::after{
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      // left: 86%;
      // top: -15px;
      top: -9px;
      right: 8px;
      z-index: -1;
      // background-color: var(--black-color);
      // -webkit-clip-path: polygon(50% 31%,0 100%,100% 100%);
      // clip-path: polygon(50% 31%,0 100%,100% 100%);
      background-image: url(../../assets/images/TrianglePic.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    * {
      margin: 0;
      padding: 0;
      font-family: inherit;
    }

    ul{
      margin: 0;
      border-radius: var(--border-radius);
      overflow: hidden;

      li {
        list-style: none;

        button {
          width: 100%;
          padding: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
          gap: 12px;
          transition: all 100ms ease;

          svg {
            width: auto;
            height: auto;
            color: var(--white-color);
            transform: scale(1);
            transition: all 100ms ease;
          }

          span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--white-color);
            white-space: nowrap;
          }

          &:hover {
            svg {
              transform: scale(1.25);
            }
          }
          &:hover, &.active {
            svg, span {
              color: var(--secondary-color);
            }
          }
        }
        
        &:nth-last-child(2) {
          button {
            gap: 8px;
          }
        }
      
        &:nth-last-child(1) {
          button {
            gap: 4px;
          }
        }
        
        &:last-child{
          button{
            border-top: 1px solid var(--neutral80-color);
          }
        }
      }
    }
  }
}