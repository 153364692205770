/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {

}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 8px;
  min-height: 59px;
  margin-top: 8px !important;
  // min-width: 373px;

}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
  padding: 0;
  margin: 0 !important;

  .lf-toast-icon {
    flex: 0 0 24px;
  }
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
  padding: 1rem;

  .toast-title {
    font-size: 1rem;
    margin-left: 4px;
    margin-bottom: 8px;
  }

  .toast-text {
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .toast-only-title {
    font-weight: 400;
  }

  &.Toastify__toast--success {
    background: var(--black-color);
    color: var(--white-color);

    .toast-text {
      color: var(--neutral40-color);
    }
  }

  &.Toastify__toast--error {
    background: #FCEAE8;
    border: 1px solid var(--error-color);
    color: var(--error-color);
    padding: 15px;


    .toast-text {
      color: var(--error-color);
    }
  }

  &.Toastify__toast--info {
    background: var(--black-color);
    color: var(--white-color);

    .toast-text {
      color: var(--neutral40-color);
    }
  }

  

}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
}
