.carouselContainer {

    &.design-1  {
        width: 648px;
        :global(.carousel-item) {
            width: 648px;
            height: 680px;
        }
    }
    &.design-2  {
        width: 648px;
        :global(.carousel-item) {
            width: 648px;
            height: 712px;
        }
    }
    &.design-3  {
        width: 515px;
        :global(.carousel-item) {
            width: 515px;
            height: 472px;
        }
    }
    &.design-4  {
        width: 648px;
        :global(.carousel-item) {
            width: 648px;
            // height: 576px;
            height: 541.84px;
        }
    }
    &.design-5  {
        width: 664px;
        :global(.carousel-item) {
            width: 664px;
            height: 504px;
        }
    }
    
    :global(.carousel-inner) {
        // -webkit-box-reflect: below 15px -webkit-gradient(linear, right top, right bottom, from(rgba(255, 254, 254, 0)), color-stop(90%, transparent), to(rgba(255, 255, 255, 0.1)));
        border-radius: 1.5rem;
        overflow: hidden;
    }

    :global(a[role="button"]) {
        display: none;
    }

    :global(.carousel-item) {
        width: 100%;
    }
    .carouselImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media screen and (max-width: 1180px){
        display: none;
    }
}