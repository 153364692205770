.rightSidebar {
}

.sidebarHeader {
  align-items: flex-start;
  background-color: var(--neutral10-color);

  .sidebarCloseButton {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: var(--border-radius);
    color: var(--neutral80-color);
    justify-content: center;

    &:hover {
      background: none;
    }
    img {
      display: block;
      width: 25px;
      height: 25px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;

  * {
    margin: 0;
    padding: 0;
  }

  img {
    width: 56px;
    height: 56px;
    flex: 0 0 56px;
    object-fit: cover;
    object-position: center;
    border-radius: var(--border-radius);
  }

  .info {
    width: calc(100% - 56px);
    flex: 0 0 calc(100% - 56px);

    * {
      font-weight: 400;
      line-height: 1.5;
      text-transform: capitalize;
    }

    h5 {
      font-size: 1.25rem;
      color: var(--black-color);
    }
    p {
      font-size: 0.8rem;
      color: var(--neutral80-color);
    }
  }
}

.modelForm {
  div[class*='fullWidth']:nth-child(1) {
    padding-top: 0.5rem;
  }

  div[class*='fullWidth'] {
    position: relative;
    margin-bottom: 0 !important;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% + 2rem);
      height: 0.5px;
      left: -1rem;
      bottom: 0;
      background: var(--neutral40-color);
    }
  }

  div[class*='fullWidth']:nth-child(4) {
    &::after {
      display: none;
    }
  }

  label {
    margin-bottom: 0.5rem;
    color: var(--neutral80-color);
    font-size: 14px;
  }
  input,
  div[class$='-control'] {
    border-radius: var(--border-radius);
    border: 1px solid var(--neutral20-color);
    background-color: var(--white-color);

    &:hover,
    &:focus {
      border: 1px solid var(--black-color);
    }
  }
  div[class$='-control'] {
    padding: var(--border-radius);
  }
  input.form-control {
    height: 48px;
  }
  div[class$='-ValueContainer'] {
    padding: 0;
    gap: 5px;
    line-height: 1.5;
    max-height: 100%;
    text-transform: capitalize;

    div[class$='-multiValue'] {
      margin: 0;
      padding: 0 5px 0 0;
      gap: 5px;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: var(--border-radius);
      border: 1px solid var(--neutral80-color);

      div[role*='button'] {
        width: 16px;
        height: 16px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white-color);
        border: 1px solid var(--black-color);
        border-radius: 50%;

        &:hover {
          color: var(--error-color);
        }
      }
    }
    div[class$='-Input'] {
      margin: 0;
      padding: 0;
    }
  }

  div[class$='-ValueContainer'],
  div[class$='-multiValue'],
  input {
    color: var(--black-color);
  }

  span[class$='-indicatorSeparator'] {
    display: none;
  }
}

.custon-dis div[role='button'] {
  display: none !important;
}
