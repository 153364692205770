.page-nav {
  background-color: var(--header-bg-color);
  text-decoration: none;
  font-size: inherit;
  font-weight: 600;
  font-family: var(--font-family);
  color: var(--secondary-color) !important;
  z-index: 1;

  .menu {
    li {
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        border-radius: var(--border-radius);

        img,
        svg {
          margin: 0;
          width: 24px;
          flex: 0 0 24px;
          height: 24px;
          transition: all 100ms ease;
          transform: scale(1);
        }
      }

      &:hover {
        img,
        svg {
          transform: scale(1.25);
        }
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral80-color) !important;
        transition: all 100ms ease;
      }
    }
  }

  &.top-nav {
    position: fixed;
    width: 100vw;
    height: var(--header-height);
    padding: 12px 24px;
    box-shadow: 0px 1px 0px var(--menu-shadow-color);
    gap: 24px;
    // border: 1px solid var(--menu-shadow-color);

    form.search-form {
      input[type='search'] {
        padding: 8px 12px 8px 40px;
        max-width: 272px;
      }
    }

    .menu {
      gap: 16px;

      li {
        &.active,
        &:hover {
          a {
            span {
              color: var(--black-color) !important;
            }
          }
          // span {
          //   color: var(--black-color);
          // }
        }

        &.active {
          span {
            font-weight: 700;
            color: inherit;
          }
        }

        a {
          padding: 8px 4px;
          height: 40px;
        }
      }
    }

    &.top-nav.texture-nav {
      position: relative;
    }

    .nav-texture {
      & > li.breadcrumb-item {
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  &.sidemenu {
    position: relative;
    margin: 0 auto;
    padding: 24px 0;
    max-width: 184px;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background-color: var(--neutral20-color);
    }

    * {
      width: 100%;
    }

    .menu {
      gap: 8px;

      li {
        &.active,
        &:hover {
          * {
            color: var(--secondary-color) !important;
          }
          // span {
          //   color: var(--secondary-color);
          // }
        }

        a {
          padding: 8px 16px 8px 8px;
          height: 48px;

          span {
            color: inherit;
          }

          img,
          svg {
            margin: 0;
            width: 32px;
            flex: 0 0 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .user-card {
    .card .card-body .menu {
      gap: 8px;

      li {
        &.active,
        &:hover {
          a {
            * {
              color: var(--secondary-color) !important;
              font-weight: 400;
            }
          }
        }

        a {
          padding: 16px;
          height: 56px;
        }

        * {
          color: var(--white-color) !important;
        }
      }
    }
  }
}

.customizeExperienceHeader {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 72px;
  z-index: 100;

  .subHeader {
    width: 100%;
    margin: 0 auto;
    padding: 16px 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .bigButton {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.navbar-text {
  font-size: 0.8em;
  font-weight: 600;
  color: var(--neutral80-color);

  .button {
    text-decoration: none;
    font-size: inherit;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--secondary-color) !important;
  }
}

.common-nav {
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-shadow: 0px 1px 0px var(--menu-shadow-color);

  .navbar-brand {
    margin-right: 0;
    transform: translateY(-5px);
  }
}

.page-nav.common-nav {
  // height: 4rem;
  // position: fixed;
  // width: 100vw;
}

aside.sidebar {
  height: calc(100vh - 4rem);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

div.page-wrapper {
  margin-left: var(--sidebar-width);
  margin-top: var(--header-height);

  &.no-sidebar {
    margin-left: 0;
  }
}

div.full-screeen-page-wrapper {
  padding-left: 0rem !important;
  margin-top: 4rem !important;
}

form.search-form {
  .search-form-group {
    position: relative;
    overflow: hidden;
    // max-width: 300px;

    .search-icon {
      margin: 0;
      padding: 0;
      position: absolute;
      left: 12px;
      top: 8px;
      z-index: 5;

      img,
      svg {
        display: block;
        color: var(--neutral80-color);
      }
    }

    .search-form-input {
      &-custom-close {
        padding-right: 36px;
      }
    }

    .search-form-clear-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--neutral80-color);
      cursor: pointer;
      transition: all 100ms ease;
      &:hover {
        transform: translateY(-50%) scale(1.1);
      }
    }
  }
}
form.search-form,
.createOrgForm {
  input[type='search'],
  input[type='text'] {
    display: block;
    width: 100%;
    max-width: 300px;
    padding: 0.5em 0 0.5em 2.5em;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral80-color);
    font-family: var(--font-family);
    font-weight: 400;
    white-space: nowrap;
    background-color: var(--neutral20-color);
    border: 1px solid var(--neutral20-color);
    border-radius: var(--border-radius);
    height: 40px;
  }
}

.menu {
  li {
    font-size: var(--header-font-size);

    a {
      font-size: inherit;
      color: var(--neutral80-color);
      font-family: var(--font-family);
      font-weight: 400;
      white-space: nowrap;
    }

    a.active {
      color: var(--black-color);
      font-weight: 500;
    }
  }
}

.menu,
button:not(.userSelectionBtn, .btn-grid, .btn-table) {
  img {
    margin: 0;
    margin-right: 0.85em;
    display: inline-block;
    vertical-align: middle;
  }
}

.header-right-section {
  width: 100%;
  max-width: 384px;
  // flex: 0 1 calc(300px + 70.4px + 2em);
  // max-width: calc(300px + 70.4px + 2em);
  gap: 16px;

  form {
    // flex: 0 0 calc(100% - 70.4px - 2em);
    // width: calc(100% - 70.4px - 2em);
    flex: 0 1 272px;
    // width: 272px;
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
    padding: 8px 10px;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--neutral20-color);
  }

  .notification.active {
    background-color: var(--secondary-color);
  }

  .user-card {
    display: block;
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
    z-index: 100000000;

    .user-card-btn {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      object-fit: cover;
      object-position: center;
      border: none;
      border-radius: var(--border-radius);
      background-color: var(--neutral20-color);
      overflow: hidden;

      > img {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        max-width: 40px;
        max-height: 40px;
        height: auto;
      }
    }
  }
}

.navbar-brand {
  img {
    width: 120px;
  }
}

.pg-title {
  color: var(--black-color);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 124%;

  span,
  small {
    color: var(--neutral80-color);
    font-size: calc(var(--pg-title-font) / 2.85);
    font-weight: 400;
    line-height: 1.5;
    vertical-align: text-bottom;
  }
}

// Wrapper
.wrapper {
  .sidebar {
    display: block;
    position: fixed;
    padding: 1.5em 0 1.5em 1.5em;
    top: 60px;
    left: 0;
    width: var(--sidebar-width);
    height: calc(100% - 60px);
    max-height: 100vh;
    float: left;
    transition: all 0.5s ease;
    background-color: var(--white-color);
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    z-index: 105;

    .sidebar-wrapper {
      height: 100%;
      overflow-y: auto;
    }

    nav {
      padding-right: 17px;
      max-height: 425px;
      overflow-y: auto;

      .navbar-nav {
        display: block;
        height: 100%;
        position: relative;
      }
    }
  }

  .content {
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--header-height));
    //margin-left: calc(var(--sidebar-width) - 0.6rem);
    padding: 1.5rem;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.wrapper {
  .sidebar {
    display: block;
    position: fixed;
    padding: 1.5em 0 1.5em 1.5em;
    top: 60px;
    left: 0;
    width: var(--sidebar-width);
    height: calc(100% - 60px);
    max-height: 100vh;
    float: left;
    transition: all 0.5s ease;
    background-color: var(--white-color);
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    z-index: 100;

    .sidebar-wrapper {
      height: 100%;
      overflow-y: auto;
    }

    nav {
      padding-right: 17px;
      max-height: 425px;
      overflow-y: auto;

      .navbar-nav {
        display: block;
        height: 100%;
        position: relative;
      }
    }
  }

  .content {
    // width: calc(100% - var(--sidebar-width) - 1px);
    height: calc(100vh - 59px);
    //margin-left: calc(var(--sidebar-width) - 0.6rem);
    padding: 1.5rem 1.5rem 0;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .contentes {
    width: calc(100vw - 300px);
    height: 100vh;
    //margin-left: calc(var(--sidebar-width) - 0.6rem);
    padding: 1.5rem 1.5rem 0;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.body-of-main {
  display: flex;

  .main-container {
    padding: 1.5rem;

    &:not(.searchPgHeader) {
      min-height: calc(100vh - var(--header-height));
    }

    &.searchPgHeader + .main-container {
      min-height: calc(100vh - var(--header-height) - 158px);
    }
  }

  .breadcrumbHeader + .main-container {
    min-height: calc(100vh - var(--header-height) - 65px);

    .pageHeader.sticky {
      width: 100%;
      left: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: gainsboro;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #bebdbd;
    border-radius: 8px;
  }

  .sidebar {
    @include flex-grow();
    background-color: #ffffff;
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    // overflow-y: scroll;
    height: calc(100vh - var(--header-height) - 1px);

    header.organisation {
      position: relative;
      padding: 16px 20px;
      height: 64px;
      background: var(--neutral10-color);
      transition: all 0.4s ease-in-out;

      // @media screen and (max-width: 768px) {
      //   padding: 1em;
      // }

      a {
        @include d-flex();
        @include d-flex-v-center();

        cursor: pointer;
        height: 32px;
        gap: 8px;
        // border-radius: 50%;
        text-decoration: none;

        .orgImg {
          width: 32px;
          height: 32px;
          object-fit: cover;
          object-position: center;
          border-radius: var(--border-radius);
        }

        .menu-label {
          color: var(--black-color);
          text-transform: capitalize;
          // padding-right: 1em;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .downarrow {
          margin-left: auto;
        }
      }

      .org-switch {
        position: absolute;
        top: 80px;
        left: 16px;
        width: 200px;
        border: 1px solid #000;
        border-radius: var(--border-radius);
        background-color: var(--white-color);
        z-index: 100;
        overflow: hidden;

        ul {
          margin: 0;
          padding: 0;
          max-height: 300px;
          overflow-x: hidden;
          overflow-y: auto;
        }

        h5,
        li {
          margin: 0;
          padding: 16px;
          // height: 56px;
          height: auto;

          &.createOrgForm {
            height: auto;

            input {
              padding: 0.5em;
            }
            button {
              width: calc(50% - 0.3em);
              flex: 0 0 calc(50% - 0.3em);
              padding: 12px 16px;
              color: var(--black-color);
              font-size: 14px;
              line-height: 16px;
              height: 40px;
              border-radius: var(--border-radius);
            }
            .cancel {
              border: 1px solid var(--neutral80-color) !important;
              background-color: var(--background-color) !important;
              padding: 0 3px;

              span {
                font-size: 0.875rem;
              }

              &:hover,
              span:hover {
                color: var(--black-color);
              }
            }
            .save {
              background-color: var(--primary-color) !important;
              padding: 0 3px;
              font-size: 0.875rem;

              span {
                transition: none;
                font-size: 0.875rem;
              }

              &:hover,
              span:hover {
                color: var(--black-color);
              }
            }
          }

          span {
            display: block;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            color: var(--black-color);
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            transition: all 100ms ease;

            &:not(.text-muted).active,
            &:not(.text-muted):hover,
            &.text-action {
              color: var(--secondary-color);
              cursor: pointer;
            }
          }
        }

        h5 {
          border-bottom: 1px solid var(--neutral60-color);
          background-color: var(--neutral10-color);

          span {
            color: var(--neutral60-color);
          }
        }
      }
    }

    .menu {
      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          text-transform: capitalize;
          font-size: 13px;
        }
      }

      a {
        text-decoration: none;
        display: flex;
        align-items: center;
      }

      img {
        // border-radius: 8px;
        margin-left: 10px;
        margin-right: 5px;
      }

      .menu-head {
        border-top: 1px solid #e9e9e9;
      }

      .menu-item {
        @include d-flex();
        @include d-flex-v-center();
        padding: 0.8em 2.2em;
        color: $primary-text;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        .more {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #0059f1;
        }

        h6 {
          font-weight: bold;
          font-size: 16px;
        }

        @media screen and (max-width: 768px) {
          padding: 1em;
          @include d-flex-h-center();
        }
      }
    }

    .menu-icon {
      width: 30px;
      text-align: center;
    }

    .menu-label {
      // padding-left: 10px;
      color: #2d3136;

      // @media screen and (max-width: 768px) {
      //   visibility: hidden;
      //   opacity: 0;
      //   position: absolute;
      // }
    }

    .menu-label-select {
      margin-left: 10px;
    }
  }

  .sidebars {
    @include flex-grow();
    background-color: #ffffff;
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    // min-width: 200px;
    overflow-y: scroll;
    height: 93vh;

    header {
      padding: 1em 1em 1em;
      background: #f9f9f9;
      transition: all 0.4s ease-in-out;
      position: relative;

      @media screen and (max-width: 768px) {
        padding: 1em;
      }

      a {
        height: 40px;
        border-radius: 50%;
        text-decoration: none;

        @include d-flex();
        @include d-flex-v-center();

        .menu-label {
          font-size: 16px;
          color: #2d3136;
          font-weight: bold;
          font-size: 16px;
        }

        .downarrow {
          position: absolute;
          right: 6%;
        }
      }
    }

    .texture-header {
      padding: 1em 1em 1em;
      background-color: #ffffff;
    }

    .texture-menu {
      max-height: calc(100vh - var(--header-height));
    }

    .menu {
      ul {
        padding: 0;

        li {
          list-style: none;
        }
      }

      a {
        text-decoration: none;
      }

      img {
        border-radius: 8px;
        margin-left: 10px;
        margin-right: 5px;
      }

      .menu-head {
        border-top: 1px solid #e9e9e9;
      }

      .menu-item {
        @include d-flex();
        @include d-flex-v-center();
        padding: 0.8em 2.2em;
        color: $primary-text;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        .more {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #0059f1;
        }

        h6 {
          font-weight: bold;
          font-size: 16px;
        }

        label.menu-texture-item {
          width: 100%;
          display: flex;
          cursor: pointer;
        }

        // label {
        //     width: 24px;
        //     height: 24px;
        //     background: #e9e9e9;
        //     border-radius: 8px;
        // }
        // #chk {
        //     background: #e9e9e9;
        //     border-radius: 8px;
        //     border: none;
        //     display: none;
        // }
        // input:checked + label:after {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     top: 14px;
        //     left: 40px;
        //     width: 7px;
        //     height: 14px;
        //     border: solid #0079bf;
        //     border-width: 0 2px 2px 0;
        //     transform: rotate(45deg);
        // }

        @media screen and (max-width: 768px) {
          padding: 1em;
          @include d-flex-h-center();
        }
      }
    }

    .menu-icon {
      width: 30px;
      text-align: center;
    }

    .menu-label {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #2d3136;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        opacity: 0;
        position: absolute;
      }
    }

    .menu-label-select {
      margin-left: 10px;
    }

    @media screen and (max-width: 768px) {
      max-width: 70px;
    }
  }

  .contents {
    width: calc(100% - var(--sidebar-width) - 65px);
  }

  .sidebars {
    display: block;
    max-width: 300px;
    overflow: auto;
    margin-top: 1px;
    height: 100vh;
    transition: all 0.5s ease;
    background-color: var(--white-color);
    box-shadow: 1px 0px 0px var(--menu-shadow-color);
    z-index: 105;

    .sidebar-wrapper {
      height: 100%;
      overflow-y: auto;
    }

    nav {
      padding-right: 17px;
      max-height: 425px;
      overflow-y: auto;

      .navbar-nav {
        display: block;
        height: 100%;
        position: relative;
      }
    }
  }
}

.height_scroll {
  height: 100vh;
  overflow: auto;
}

.fullscreen {
  .container-fluid {
    img {
      height: 100vh;
      object-fit: cover;
    }

    .fullscreen-escbtn {
      button {
        position: absolute;
        width: 228px;
        height: 40px;
        left: calc(50% - 228px / 2);
        top: 90%;
        background: #2d3136;
        border-radius: 8px;
        border: unset;
        color: #ffffff;
      }
    }
  }
}

.wrapper .sidebar {
  display: block;
  position: fixed;
  padding: 1.5em 0 1.5em 1.5em;
  top: 60px;
  left: 0;
  width: var(--sidebar-width);
  height: calc(100% - 60px);
  max-height: 100vh;
  float: left;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--white-color);
  -webkit-box-shadow: 1px 0px 0px var(--menu-shadow-color);
  box-shadow: 1px 0px 0px var(--menu-shadow-color);
  z-index: 100;
}

.wrapper .sidebar .sidebar-wrapper {
  height: 100%;
  overflow-y: auto;
}

.wrapper .sidebar nav {
  padding-right: 17px;
  max-height: 425px;
  overflow-y: auto;
}

.wrapper .sidebar nav .navbar-nav {
  display: block;
  height: 100%;
  position: relative;
}

.wrapper .content {
  width: calc(100vw - 300px);
  height: calc(100vh - 59px);
  /* margin-left: calc(var(--sidebar-width) - 0.6rem); */
  padding: 1.5rem 1.5rem 0;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll-container {
  min-height: 200px;

  &.fixed {
    > .progressContainer {
      position: fixed;
      top: calc(50% + var(--header-height) / 2);
      left: calc(50% + var(--sidebar-width) / 2);
      z-index: 10000;
    }
  }
}

.scroll-loader {
  height: 80px;
  width: 100%;
}

.grid-section {
  &:not(.actions) {
    margin-bottom: 32px;
  }
  // width: calc(100vw - 264px) !important;

  &.actions {
    margin-bottom: 40px;
  }

  .empty-area {
    height: 285px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #6c757d;

    button {
      font-size: 12px;
    }
  }
}

.wrapper .sidebar {
  display: block;
  position: fixed;
  padding: 1.5em 0 1.5em 1.5em;
  top: 60px;
  left: 0;
  width: var(--sidebar-width);
  height: calc(100% - 60px);
  max-height: 100vh;
  float: left;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--white-color);
  -webkit-box-shadow: 1px 0px 0px var(--menu-shadow-color);
  box-shadow: 1px 0px 0px var(--menu-shadow-color);
  z-index: 105;
}

.wrapper .sidebar .sidebar-wrapper {
  height: 100%;
  overflow-y: auto;
}

.wrapper .sidebar nav {
  padding-right: 17px;
  max-height: 425px;
  overflow-y: auto;
}

.wrapper .sidebar nav .navbar-nav {
  display: block;
  height: 100%;
  position: relative;
}

.wrapper .content {
  width: calc(100vw - var(--sidebar-width));
  height: calc(100vh - var(--header-height));
  /* margin-left: calc(var(--sidebar-width) - 0.6rem); */
  padding: 24px;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.container-padding-top {
  padding-top: 5rem;
}

#whiteShadow {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
  content: '\00a0';
  height: 10%;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.topMenuNav.show {
  z-index: 2000;
}

.loaderV2 {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  &.backdrop {
    background: rgba(255, 255, 255, 0.5);
  }

  .progressContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
}

.circularProgress {
  width: 32px;
  height: 32px;
  border: 4px solid var(--primary-color);
  border-left: 4px solid transparent;
  border-radius: 50%;
  animation: loadingAnimation 0.5s linear 0s infinite;

  @keyframes loadingAnimation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.texture3d-preview-select-action {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.texture3d-preview-wrapper {
  width: 98%;
  background: #ffffff;
  border-radius: 8px;
  margin-left: 2%;
  padding: 20px;

  .texture3d-preview-header {
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: space-between;
    align-items: center;

    & > h4 {
      margin: 0;
    }
  }

  .texture3d-preview-content {
    position: relative;
    height: 400px;

    .texture3d-preview-select {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      & > .texture3d-preview-select-item {
        margin: 1rem;
        cursor: pointer;
      }
    }
  }
}

.texture-upload-modal {
  .modal-content {
    width: 528px;

    .modal-title {
      p.title {
        padding: 0;
        margin-bottom: 8px;
        font-size: 2rem;
        font-weight: 700;
      }

      p.sub-title {
        padding: 0;
        font-size: 1.5rem;
        font-weight: 300;
      }
    }

    .modal-body {
      .dropzone {
        p.dz-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5rem;
          color: var(--render-selected);
        }
        p.dz-sub-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5rem;
          color: var(--neutral80-color);
        }
      }
    }
  }
}
.body-of-main {
  &.texture-side-section {
    width: 100%;
    height: calc(100vh - var(--header-height));
    overflow: hidden;

    .texture-header {
      padding: 12px 1.5rem;

      h4 {
        margin: 0;
      }
    }

    .texture-menu {
      padding: 0 0.5rem;

      .custom-checkbox {
        padding: 0.8rem 1rem !important;
        border-radius: 8px;

        &.has-error {
          background-color: #fceae8;
          border: 1px solid #e4291d;
        }

        .form-check-input {
          min-width: 24px;
          min-height: 24px;
          margin-top: 0;
        }

        .menu-texture-item {
          align-items: center;

          img {
            margin: 0 !important;
          }
        }
      }
    }
  }
}

/** SPINNER CREATION **/

.loader {
  position: relative;
  text-align: center;
  margin: 15px auto 35px auto;
  z-index: 9999;
  display: block;
  width: 80px;
  height: 80px;
  border: 10px solid rgba(255, 162, 0, 0.3);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.loader-txt {
  text-align: center;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

table.bordered {
  width: 100%;
  border: solid var(--menu-shadow-color) 1px;
  border-collapse: separate !important;
  border-spacing: 0;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 1px var(--menu-shadow-color);
  -moz-box-shadow: 0 1px 1px var(--menu-shadow-color);
  box-shadow: 0 1px 1px var(--menu-shadow-color);

  td {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--menu-shadow-color);

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span:first-child {
      font-size: 14px;
    }
  }

  td:first-child {
    border-left: none;
  }

  tr:last-child td:first-child {
    -moz-border-radius: 0 0 0 6px;
    -webkit-border-radius: 0 0 0 6px;
    border-radius: 0 0 0 6px;
  }

  tr:last-child td:last-child {
    border-bottom: none;
    -moz-border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 0 6px 0;
    border-radius: 0 0 6px 0;
  }

  tr:nth-child(2n) {
    background-color: var(--neutral10-color);
  }
}

.slick-track {
  margin-left: 0 !important;
}

.permission-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;

  .permission-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-left: 1px solid var(--neutral20-color);
    border-right: 1px solid var(--neutral20-color);
    background-color: var(--white-color);

    &:nth-last-child(2),
    &:last-child {
      border-bottom: 1px solid var(--neutral20-color);
      border-radius: 0 0 0.5rem 0.5rem;
    }

    &:nth-child(2),
    &:first-child {
      border-top: 1px solid var(--neutral20-color);
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &:nth-child(4n-3),
    &:nth-child(4n-4) {
      background-color: var(--neutral10-color);
    }

    @include if(1) {
      border-radius: 0.5rem;
    }

    @include if(2) {
      border-radius: 0.5rem;
    }

    @include if(3) {
      border-radius: 0.5rem;

      &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
      }

      &:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }
  }
}

.preview-image-side-section {
  .custom-card-section {
    display: inherit !important;
    grid-template-columns: inherit !important;
    .thumbnail-card {
      margin-bottom: 8px !important;
    }

    .imageRoot img {
      object-fit: contain;
    }
    // .imageRoot {
    //   img {
    //     max-width: 100%;
    //     position: absolute;
    //     left: 50%;
    //     right: 0;
    //     width: auto;
    //     height: 100%;
    //     transition: opacity 0.2s ease;
    //     object-fit: contain;
    //     transform: translate(-50%, 0);
    //   }
    // }
  }
}

.top-nav {
  z-index: 999;
}

.support-table {
  .support-item {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0;
    padding: 5px;
    border-radius: 0.5rem;

    .support-item-image {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
      border-radius: 0.5rem;
    }

    .support-item-content {
      flex: 1;

      .title {
        font-size: 12px;
        line-height: 24px;
        color: #000000;
      }

      .sub-title {
        font-size: 10px;
        line-height: 16px;
        color: #3f494f;
      }
    }

    .support-item-details {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: space-evenly;
      font-size: 0.5rem;
      color: #93a4b6;
    }
  }
}

.chat-support {
  .chat-support-header {
    height: 49px;
    background-color: #ffffff;
    border: 1px solid #e2e2e2;
  }
  .chat-support-content {
  }
  .chat-support-actions {
  }
}

.activeTexture {
  background-color: var(--light-blue);
  margin-bottom: 4px;
}
.uploadTextureButton {
  background-color: #ccdefc !important;
  min-width: 100% !important;
  border-radius: 8px !important;
  height: 44px !important;
  margin-bottom: 4px !important;

  span {
    color: #0059f1 !important;
    font-weight: 400;
    size: 16px;
    line-height: 24px;
  }
}

.uploadTextureButton:hover {
  background-color: #ccdefc !important;
  min-width: 100% !important;
  border-radius: 8px !important;
  height: 44px !important;
  margin-bottom: 4px !important;
  color: #0059f1 !important;
}
