.searchInputContainer {
  position: relative;
  margin-bottom: 1.5rem;
}

.clearInputButton {
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.searchInput {
  width: 100%;
  height: 48px;
  background: var(--white-color);
  border: 1px solid var(--black-color);
  border-radius: var(--border-radius);

  &:focus {
    background: var(--white-color);
  }
}