.root {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--white-color);
  z-index: 5;
}

.keepOnTop {
  z-index: 1000;
}

.infoIcon {
  width: 40px;
  height: 40px;
  color: --var(--black-color);
  cursor: pointer;

  &:hover {
    color: #3d4145;
  }
}

.popup {
  position: absolute;
  bottom: calc(100% + 11px);
  right: 0;
}

.warningList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .warning {
    margin: 10px 0 0;
    word-break: keep-all;
    white-space: nowrap;
    background: #FCEAE8;
    border: 1px solid var(--error-color);
    color: var(--error-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 7px;
    overflow: hidden;

    &:hover {
      .eyeIcon {
        transform: scale(0);
      }

      .closeIcon {
        transform: translateY(-50%) scale(1);
      }
    }
  }

  .eyeIcon {
    margin-right: 1rem;
    transition: all 0.2s ease;
    transform: scale(1);
  }

  .closeIcon {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%) scale(0);
    left: 9px;
    transition: all 0.2s ease;
  }
}


.menu {
  background: var(--black-color);
  border: 1px solid #5A5C61;
  border-radius: 8px;
  margin-top: 1rem;


  .menuItem {
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 1rem;
    color: var(--white-color);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #3d4145;
    }

    svg {
      margin-right: 0.5rem;
    }

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
}