.form {
  .checkboxContainer {
    gap: 16px;
  }

  .nameInput {
    padding: 12px;
    height: 48px;
    border-radius: 8px !important;
    border: 1px solid var(--neutral20-color);

    &.renderNameInput {
      background-color: var(--white-color);
    }
  }

  :global(.form-check) {
    margin-bottom: 0;
    min-width: 47%;
  }

  &.sidebarForm {
    .checkboxContainer {
      justify-content: space-between;
    }
    :global(.form-check) {
      min-width: 35%;
    }
  }

  :global(.form-check-input) {
    background: #5A5C61;
  }


  :global(.input-group-append) {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 4.238rem;
    border: none;
    border-left: 1px solid var(--neutral20-color);
    border-radius: 8px;
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      background: url(../../../../../assets/images/curator/select-arrow.svg) no-repeat center center;
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }

  :global(.rp-type) {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 46px;
    border-radius: 0;
    border: none;
    outline: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    text-align: left;
    padding-left: 12px;
    color: var(--neutral80-color);

    option {
      border-radius: 0;
      color: var(--neutral80-color);
    }
  }

  :global(.drop-input) {
    position: relative;
  }
}